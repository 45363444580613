<template>
    <b-overlay :show="showLoader">
        <div v-if="order" class="my-order">
            <h1 class="text-secondary font" style="font-size: xx-large">Podrobnosti naročila</h1>

            <div class="mt-4 d-flex justify-content-between align-items-center">
                <h3 class="mb-0 font">Številka naročila {{order.bbis_order_id}} - oddano {{dayjs(order.date_created).format('DD.MM.YYYY')}}</h3>
                <b-button v-if="order.status.length === 2 && order.status[1].status === 1" variant="primary" squared @click="$router.push({name: 'pay-with-card', params: {order_id: order.id}})">Plačaj</b-button>
            </div>

            <div class="mt-4">
                <h3 class="font">Dostava:
                    <span v-if="order.shipping_type === 1" class="font-weight-normal">Dostava po pošti</span>
                </h3>
                <h3 class="font">Plačilo:
                    <span v-if="order.payment_type === 1" class="font-weight-normal">Plačilo prek spleta (Visa, Mastercard, American Express)</span>
                    <span v-else-if="order.payment_type === 2" class="font-weight-normal">Plačilo preko upravnika</span>
                    <span v-else-if="order.payment_type === 3" class="font-weight-normal">Plačilo preko upravnika na obroke ({{order.number_of_installments}})</span>
                </h3>
                <span class="mt-1 cursor-pointer d-flex align-items-center">
                    <div>
                        <img width="35px" height="35px" :src="require('@/assets/svg/pdf_icon.svg')"/>
                    </div>
                    <h3 @click="downloadNarociloPDF(order,`Narocilo ${order.bbis_order_id}`)" class="ml-1 mb-0 text-secondary">Shranite vaš račun kot PDF datoteko</h3>
                </span>
            </div>

            <div class="mt-4">
                <h3 class="font">VAŠE NAROČILO PO KORAKIH</h3>
                <div class="mt-2">
                    <b-table class="table-step" responsive striped :items="order.status" :fields="fieldsOrderSteps">
                        <template #cell(date_created)="row">
                            <span>{{dayjs(row.item.date).format('DD.MM.YYYY h:mm')}}</span>
                        </template>
                        <template #cell(status)="row">
                            <b-badge v-if="row.item.status === 0" variant="secondary">Naročilo oddano</b-badge>
                            <b-badge v-else-if="row.item.status === 1" variant="info">Naročilo potrjeno</b-badge>
                            <b-badge v-else-if="row.item.status === 2" variant="danger">Naročilo zavrnjeno</b-badge>
                            <b-badge v-else-if="row.item.status === 3" variant="warning">Čakam na plačilo</b-badge>
                            <b-badge v-else-if="row.item.status === 4" variant="danger">Plačilo zavrnjeno</b-badge>
                            <b-badge v-else-if="row.item.status === 5" variant="success">Plačilo potrjeno</b-badge>
                            <b-badge v-else-if="row.item.status === 6" variant="info">Naročilo v obdelavi</b-badge>
                            <b-badge v-else-if="row.item.status === 7" variant="success">Naročilo zaključeno</b-badge>
                        </template>
                    </b-table>
                </div>
            </div>
            <div class="mt-1" v-if="order.status && order.status.length > 0 && order.status[order.status.length - 1].status === 3">
                <div style="color: red;">
                    Naročilo še ni bilo plačano. Če želiti plačati kliknite spodnji gumb.
                </div>
                <b-button class="mt-1" @click="redirectToPayment">Plačaj naročilo</b-button>
            </div>

            <div class="mt-4">
                <b-row>
                    <b-col cols="12" md="6" lg="4">
                        <h3 class="font">NASLOV ZA DOSTAVO</h3>
                        <h3>{{order.delivery_address.name}} {{order.delivery_address.surname}}</h3>
                        <h3>{{order.delivery_address.street}}</h3>
                        <h3>{{order.delivery_address.post}}</h3>
                        <h3>{{order.delivery_address.country}}</h3>
                    </b-col>
                    <b-col cols="12" md="6" lg="4" class="mt-1 mt-md-0">
                        <h3 class="font">NASLOV ZA RAČUN</h3>
                        <h3>{{order.invoice_address.name}} {{order.invoice_address.surname}}</h3>
                        <h3>{{order.invoice_address.street}}</h3>
                        <h3>{{order.invoice_address.post}} {{order.invoice_address.city}}</h3>
                        <h3>{{order.invoice_address.country}}</h3>
                    </b-col>
                </b-row>
            </div>

            <div class="mt-4">
                <b-table-simple class="table-items" :responsive="true">
                    <b-thead>
                        <b-tr>
                            <b-th>Izdelek</b-th>
                            <b-th>Količina</b-th>
                            <b-th>Cena na enoto</b-th>
                            <b-th>Skupaj</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody class="bg-white">
                        <b-tr v-for="(item, index) of order.items" :key="'item_'+index">
                            <b-td class="pr-0">
                                <div>
                                    {{item.description_1}}
                                </div>
                                <div style="font-size: 12px; font-color: " v-if="item.promotional_price">
                                    <span style="">{{getCouponPrefixText(item.number_of_coupons)}} </span> <span style="font-weight: bold; color: #72a5d8;">{{item.number_of_coupons}} {{getCouponText(item.number_of_coupons)}}</span>
                                </div>
                            </b-td>
                            <b-td>{{item.quantity}}</b-td>
                            <b-td>{{$convertPriceIntegerToFloat(item.total_price / item.quantity)}} €</b-td>
                            <b-td>
                                <div>
                                    {{$convertPriceIntegerToFloat(item.total_price)}} €
                                </div>
                            
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-td></b-td>
                            <b-td></b-td>
                            <b-td class="font">Skupaj</b-td>
                            <b-td>{{$convertPriceIntegerToFloat(order.total_price)}} €</b-td>
                        </b-tr>
                        <b-tr>
                            <b-td></b-td>
                            <b-td></b-td>
                            <b-td class="font">Popust</b-td>
                            <b-td>0.00 €</b-td>
                        </b-tr>
                        <b-tr>
                            <b-td></b-td>
                            <b-td></b-td>
                            <b-td class="font">{{getCouponPrefixText(total_coupons)}}</b-td>
                            <b-td style="color: #72a5d8;">{{total_coupons}} {{couponAllText}}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-td></b-td>
                            <b-td></b-td>
                            <b-td class="font">Dostava</b-td>
                            <b-td>{{$convertPriceIntegerToFloat(order.shipping_price)}} €</b-td>
                        </b-tr>
                        <b-tr>
                            <b-td></b-td>
                            <b-td></b-td>
                            <b-td class="font">Cena skupaj</b-td>
                            <b-td>{{$convertPriceIntegerToFloat(totalPrice)}} €</b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </div>
        </div>
    </b-overlay>
</template>

<script>
    import {BOverlay, BTable, BBadge, BRow, BCol, BTableSimple, BThead, BTbody, BTr, BTh, BTd, BButton} from 'bootstrap-vue'

    export default {
        components: {BOverlay, BTable, BBadge, BRow, BCol, BTableSimple, BThead, BTbody, BTr, BTh, BTd, BButton},
        data() {
            return {
                totalCoupons: 0,
                couponAllText: '',
                couponText: '',
                showLoader: false,
                order: null,
                fieldsOrderSteps: [
                    { key: 'date_created', label: 'Datum', sortable: false, class: 'text-left' },
                    { key: 'status', label: 'Stanje', sortable: false, class: 'text-left' }
                ],
                itemsToDisplay: [],
                totalPrice: 0
            }
        },
        methods: {
            redirectToPayment() {
                this.$router.push({name: 'pay-with-card', params: {order_id: this.$route.params.order_id}})
            },
            loadData() {
                const thisIns = this
                thisIns.showLoader = true

                thisIns.$http.get(`/api/user/v1/web_shop/order/${this.$route.params.order_id}`)
                    .then(res => {
                        this.order = res.data
                        this.totalPrice = this.order.shipping_price + this.order.total_price

                        this.checkCoupons()
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri nalaganju podatkov je prišlo do napake!\n${error.message}`)
                    }).finally(() => {
                        thisIns.showLoader = false
                    })
            },
            downloadNarociloPDF(item, pdf_title) {
                const thisIns = this
                this.$http({
                    url: `/api/user/v1/web_shop/narocilo/${item.bbis_order_id}`, //your url
                    method: 'GET',
                    responseType: 'blob' // important
                })
                    .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]))
                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute('download', `${pdf_title}.pdf`) //or any other extension
                        document.body.appendChild(link)
                        link.click()
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri nalaganju pdf je prišlo do napake!\n${error.message}`)
                    })
            },
            getCouponText(num) {
                let  text = ''

                if (num === 1) {
                    text = 'Kupon'
                } else if (num === 2) {
                    text = 'Kupona'
                } else if (num < 5) {
                    text = 'Kuponi'
                } else  {
                    text = 'Kuponov'
                }

                return text
            },
            getCouponPrefixText(num) {
                let  text = ''

                if (num === 1) {
                    text = 'Porabljen'
                } else if (num === 2) {
                    text = 'Porabljena'
                } else if (num < 5) {
                    text = 'Porabljeni'
                } else  {
                    text = 'Porabljenih'
                }

                return text
            },
            checkCoupons() {
                this.total_coupons = 0
                for (const item of this.order.items) {
                    if (item.promotional_price && item.number_of_coupons) {
                        this.total_coupons += item.number_of_coupons
                    }
                }

                if (this.total_coupons === 1) {
                    this.couponAllText = 'Kupon'
                } else if (this.total_coupons === 2) {
                    this.couponAllText = 'Kupona'
                } else if (this.total_coupons < 5) {
                    this.couponAllText = 'Kuponi'
                } else  {
                    this.couponAllText = 'Kuponov'
                }

            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style>
.my-order .font {
  font-weight: bold;
}

.my-order .table-step tr td {
  background-color: white !important;
}
.my-order .table-step tr th {
  border: none !important;
  text-transform: none;
  font-weight: bold;
  font-size: 20px;
  background-color: #dbe9f5  !important;
}

.my-order .table-step tr td {
  border-top: none !important;
  text-transform: none;
  font-size: 17px;
}

.my-order .table-items th {
  border: none !important;
  background-color: #dbe9f5  !important;
  text-transform: none;
  font-weight: bold;
  font-size: 18px;
}
.my-order .table-items thead th:nth-child(4), .my-order .table-items tbody td:nth-child(4){
  text-align: right;
}
.my-order .table-items th, .my-order .table-items td {
  padding: 0.72rem 1rem !important;
  min-width: 160px !important;
}
.my-order .table-items tr:first-child td, .my-order .table-items tr:last-child td, .my-order .table-items tr:nth-last-child(2) td {
  border: none !important;
}
.my-order .table-items td {
  font-size: 17px;
  border-top: 1px solid black !important;
}
.my-order .table-items td:nth-child(3) {
  text-align: end;
  width: 160px;
}
.my-order .table-items td:nth-child(1) {
  min-width: 200px !important;
}
.my-order .badge {
  border-radius: 0;
  padding: 0.6rem 0.8rem;
  width: 130px;
  font-size: 12px;
  font-weight: normal;
}
</style>